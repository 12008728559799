<template>
  <div class="w-full">
    <div
      class="border border-[#DCDFE6] rounded-xl w-full px-2 mb-1"
      v-if="showTag"
      v-show="nodes.length"
    >
      <el-tag
        closable
        v-for="item in nodes"
        :key="item[nodeKey]"
        class="mr-1"
        @close="onClose(item)"
        >{{ item[props.label] }}</el-tag
      >
    </div>
    <div class="relative w-full">
      <el-popover
        v-model="showPopover"
        trigger="click"
        popper-class="w-full"
        placement="bottom-start"
        @show="onShow"
        :width="offsetWidth"
        ref="popover"
      >
        <el-input
          slot="reference"
          v-model="filterText"
          class="w-full cuseTsdas"
          placeholder="请输入"
          ref="input"
        ></el-input>
        <el-tree
          class="w-full overflow-auto"
          :data="data"
          :props="props"
          :show-checkbox="showCheckbox"
          :node-key="nodeKey"
          :default-expand-all="defaultExpandAll"
          :checkStrictly="checkStrictly"
          style="max-height: 350px"
          ref="elTree"
          :filter-node-method="filterNode"
          @check="onCheck"
        >
          <template slot-scope="{ node, data }">
            <slot
              :node="node"
              :data="data"
            >
              <span>{{ node.label }}</span>
            </slot>
          </template>
        </el-tree>
      </el-popover>
    </div>
  </div>
</template>
<script>
/**
 * 事件 input
 * change
 * */

import { selectTreePropsType } from './index';
export default {
  props: { ...selectTreePropsType },
  data() {
    return {
      offsetWidth: null,
      nodes: [],
      filterText: '',
      showPopover: false,
    };
  },
  methods: {
    onShow() {
      this.offsetWidth = this.$refs.input.$el.offsetWidth;
    },
    onClose(node) {
      let ids = [...this.value];
      const index = ids.findIndex((item) => item == node.id);

      if (index >= 0) {
        ids.splice(index, 1);
        this.onCheck(node, { checkedKeys: ids });
      }
    },
    filterNode(value, data, node) {
      if (!value) return true;
      if (data[this.props.label].indexOf(value) != -1) {
        return true;
      }
      return this.filterParentNode(value, node);
    },
    filterParentNode(value, node) {
      // 含有父节点
      if (node.parent.level > 0) {
        if (node.parent.data[this.props.label].indexOf(value) != -1) {
          return true;
        }
        return this.filterParentNode(value, node.parent);
      }
      return false;
    },
    // 更新树节点
    updateKeyChildren(value) {
      if (!this.data || !this.data.length) {
        return;
      }
      if (this.$refs.elTree) {
        this.$refs.elTree.setCheckedKeys(value, false);
        this.getCheckedNodes();
      }
    },
    // 复选框发生变化
    onCheck(data, list) {
      this.$emit('check', {
        data,
        list,
        checkedKeys: list.checkedKeys,
        checked: list.checkedKeys.includes(data[this.nodeKey]),
      });
      this.$nextTick(() => {
        this.onChange([...list.checkedKeys]);
        this.getCheckedNodes();
      });
    },
    getCheckedNodes() {
      this.$nextTick(() => {
        this.nodes = this.$refs.elTree.getCheckedNodes(!this.checkStrictly);
        this.$emit('getData', this.value, this.nodes);
        if (this.nodes.length != this.value.length) {
          const value = this.nodes.map((item) => item[this.nodeKey]);
          this.onChange(value);
        }
      });
    },
    onChange(list) {
      this.$emit('input', list);
      this.$emit('change', list);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.updateKeyChildren(val);
        });
      },
    },
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.updateKeyChildren(this.value);
        });
      },
    },
    filterText(val) {
      this.showPopover = true;
      this.$refs.elTree.filter(val);
    },
  },
};
</script>
